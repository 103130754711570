import EnquiryForm from "@components/forms/EnquiryForm"
import CloseIcon from "@components/icons/CloseIcon"
import loadable from "@loadable/component"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import "./EnquiryModal.scss"
import { ENQUIRY_FORMS } from "@components/forms/utils"

export const EnquiryModal = ({ show, handleClose, choose_form }) => {
  let title = ""
  let description = ""
  switch (choose_form) {
    case ENQUIRY_FORMS.mortagage:
      title = "Request Mortgage Advice"
      description = "Please fill in your details below."
      break
    case ENQUIRY_FORMS.snagging:
      title = "Request Snagging Advice"
      description = "Please fill in your details below."
      break
    case ENQUIRY_FORMS.prism:
      title = "Request PRISM Advice"
      description = "Please fill in your details below."
      break
      case ENQUIRY_FORMS.prismPopup:
        title = "Request PRISM Advice"
        description = "Please fill in your details below."
        break
    case ENQUIRY_FORMS.primestay:
      title = "Request PRIMESTAY Advice"
      description = "Please fill in your details below."
      break
    case ENQUIRY_FORMS.conveyancing:
      title = "Request Conveyancing Advice"
      description = "Please fill in your details below."
      break
      case ENQUIRY_FORMS.conveyancingPopup:
      title = "Request Conveyancing Advice"
      description = "Please fill in your details below."
      break
    case ENQUIRY_FORMS.propertyLeasing:
      title = "Request Property Leasing Advice"
      description = "Please fill in your details below."
      break
      case ENQUIRY_FORMS.propertyLeasingPopup:
        title = "Request Property Leasing Advice"
        description = "Please fill in your details below."
        break
    case ENQUIRY_FORMS.propertyMangement:
      title = "Request Property Management Advice"
      description = "Please fill in your details below."
      break
    default:
      title = "Request Details"
      description = "Please fill in your details below to request details."
  }

  // const EnquiryForm = loadable(() => import("@components/forms/EnquiryForm"))

  return (
    <Modal show={show} onHide={handleClose} centered className="mortgage-modal">
      <Modal.Body className="mortgage-modal-body">
        <div className="mortgage-modal-header">
          <div className="content-section">
            <h3 className="title">{title}</h3>
            <p className="description">{description}</p>
          </div>
          <CloseIcon onClick={handleClose} />
        </div>
        <EnquiryForm noScrollOnSuccess choose_form={choose_form} />
      </Modal.Body>
    </Modal>
  )
}

const EnquiryModalWithTrigger = ({
  buttonText,
  children,
  triggerClassName,
  className,
  choose_form,
}) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => {
    if(buttonText === "Get a free consultation"){
      if(typeof window !== "undefined"){
        try {
          window.__adroll.record_user({"adroll_segments": "6fc953c7"});
      } catch(err) {
          console.error('Error recording AdRoll user:', err);
      }
    }

    }
     setShow(true)
  }

  // if (!offplan?.brochure?.file?.url) return null
  return (
    <>
      <button
        className={
          className || triggerClassName || "button button-orange trigger-button"
        }
        onClick={handleShow}
      >
        {children || <span>{buttonText || "Request Advice"}</span>}
      </button>
      <EnquiryModal
        show={show}
        handleClose={handleClose}
        choose_form={choose_form}
      />
    </>
  )
}

export default EnquiryModalWithTrigger
