import React, { useRef, useState } from "react"

// import { isEmpty } from "lodash"

import clsx from "clsx"
import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import Form from "./elements/Form"
import PhoneInput from "./elements/PhoneInput"
import Recaptcha from "./elements/Recaptcha"
import TermsConditions from "./elements/TermsConditions"
import TextAreaInput from "./elements/TextAreaInput"
import TextInput from "./elements/TextInput"
import { enquirySchema } from "./schemas"
import {
  ENQUIRY_FORMS,
  formTracking,
  generateUuid,
  getBitrixData,
  isProduction,
  postFormData,
  sendMail,
  useBlock,
} from "./utils"
import LanguageSelect from "./elements/LanguageSelect"
import useEmailContent from "@hooks/dataHooks/useEmailContent"
import { useGlobalStore } from "@stores/globalStore"
const EnquiryForm = ({ choose_form, noScrollOnSuccess }) => {
  const recaptchaRef = useRef()
  const data = useEmailContent()
  const { ipAddress, setgaEmail, setShowSuccessMsg } = useGlobalStore()
  let formConfig = FORM_CONFIG.generalEnquiry
  let emailContentTemp = data?.filter((item) => item.choose_template ===  formConfig?.emailTempUser);
  const emailContent = emailContentTemp[0]?.content?.data?.content;
  const [isLoading, setIsLoading] = useState(false);
  switch (choose_form) {
    case ENQUIRY_FORMS.propertyMangement:
      formConfig = FORM_CONFIG.propertyMangement
      break
      case ENQUIRY_FORMS.shortTerm:
      formConfig = FORM_CONFIG.shortTerm
      break      
    case ENQUIRY_FORMS.mortagage:
      formConfig = FORM_CONFIG.mortagage
      break
    case ENQUIRY_FORMS.partnerProgram:
      formConfig = FORM_CONFIG.partnerProgram
      break
    case ENQUIRY_FORMS.snagging:
      formConfig = FORM_CONFIG.snagging
      break
    case ENQUIRY_FORMS.prism:
      formConfig = FORM_CONFIG.prism
      break
    case ENQUIRY_FORMS.primestay:
      formConfig = FORM_CONFIG.primestay
      break
    case ENQUIRY_FORMS.conveyancing:
      formConfig = FORM_CONFIG.conveyancing
      break
      case ENQUIRY_FORMS.conveyancingPopup:
        formConfig = FORM_CONFIG.conveyancing
        break
    case  ENQUIRY_FORMS.propertyLeasingPopup:
      formConfig = FORM_CONFIG.propertyLeasing
      break
      case ENQUIRY_FORMS.propertyLeasing:
        formConfig = FORM_CONFIG.propertyLeasing
        break
    case ENQUIRY_FORMS.ifxCalculator:
      formConfig = FORM_CONFIG.propertyLeasing
      break
      case ENQUIRY_FORMS.ifxCalculatorPopup:
      formConfig = FORM_CONFIG.propertyLeasing
      break
      case ENQUIRY_FORMS.eventForm:
        formConfig = FORM_CONFIG.event
        break
    default:
      formConfig = FORM_CONFIG.generalEnquiry
  }

  const onSubmit = async (data) => {
    setIsLoading(true); // Start loading

    const ipList = await useBlock();
    const isBlocked = ipList?.ip?.some(item => item?.add_ip_address === ipAddress);
    const isBlocked1 = ipList?.email?.some(item => item?.email === data.email);
    setgaEmail(data?.email)
    const token = await recaptchaRef.current?.executeAsync()

    if (token) {
      const unique_id = generateUuid()
      const postData = {
        first_name: data.name?.split(" ")[0],
        ...data,
        ...formConfig,
        unique_id: unique_id,
        "g-recaptcha-response": token,
        extra: { formConfig, data },
      }
      const bitrixData = getBitrixData(postData)

      const formData = new FormData()

      formData.append("data", JSON.stringify(bitrixData))

      recaptchaRef.current?.reset()
      if(isBlocked || isBlocked1){
        setIsLoading(false); // End loading
        return;
      }
      else {
        try {
          setTimeout(() => {
            setShowSuccessMsg(true)
          }, 1000)
          postFormData(formData);
          sendMail(bitrixData);
          formTracking({
            unique_id: unique_id,
            eventTracking: formConfig.eventTracking,
            formName: formConfig.form_name,
            email: data?.email,
            phone: data?.telephone
          })
        } catch (error) {
          setIsLoading(false); // End loading
          console.error("Error submitting form:", error);
        } finally {
          setIsLoading(false); // End loading
        }
      
    }
    }
    
  }

  return (
    <Form
      className={clsx("team-contact-form", choose_form)}
      validationSchema={enquirySchema}
      onSubmit={onSubmit}
      successMsg={SUCCESS_MSG}
      noScrollOnSuccess={noScrollOnSuccess}
    >
      <div className="form-grid">
        <TextInput name="name" placeholder="Name" label="Name" />
        <TextInput
          name="email"
          placeholder="Email"
          type="email"
          label="Email"
        />
        <PhoneInput name="telephone" label="Phone" />
        <LanguageSelect />
        <TextAreaInput
          name="message"
          placeholder="Share any additional information"
          label="Message"
        />
        <div className="d-none"><TextInput name="emailContent" type="hidden" value={emailContent} /></div>
      </div>
      <div className="form-bottom">
        <button className="button button-orange" type="submit">
          <span>{isLoading ? "Loading..." : "Submit Details"}</span>
        </button>
        <TermsConditions />
      </div>
      <Recaptcha recaptchaRef={recaptchaRef} />
    </Form>
  )
}

export default EnquiryForm
